'use client';

import { Col, Row, Skeleton } from "antd";
import styled from "styled-components";
import { MEDIA_SCREENS, THEME_COLORS, THEME_VARS } from '../../theme/constants';
import { CardSlider } from "@components/common/CardProducto";
import { useSelector } from '@redux/store';
import { useEffect, useRef, useState } from "react";
import { formatMoney } from "utils/moneyUtils";
import TransacLayout from "@layouts/TransacLayout";
import BoxUi from "@components/common/BoxUi";


const TransaccionesPage = () => {

    //CONSUMIR
    const { personaNatural, personaJuridica, loading, saldos } = useSelector(state => state.resumenPersona);
    const [nombreCliente, setNombreCliente] = useState('');
    const [screenWidth, setScreenWidth] = useState();
    //const nombre_cliente = useRef('');
    let nombre_cliente = '';
    const saldo_total = useRef(0);

    // const getNombreCliente = (personaNatural, personaJuridica) => {
    //     if (personaNatural !== null) {
    //         return `${personaNatural.primer_nombre} ${personaNatural.primer_apellido} ${personaNatural.segundo_apellido}`;
    //     }
    //     return personaJuridica.nombreComercial;
    // };

    // useEffect(() => {
    //     nombre_cliente.current = getNombreCliente(personaNatural, personaJuridica);
    //     setNombreCliente(nombre_cliente.current);
    // }, [personaNatural, personaJuridica]);

    useEffect(() => {
        personaNatural !== null
            ? nombre_cliente = personaNatural.primer_nombre + ' ' + personaNatural.primer_apellido + ' ' + personaNatural.segundo_apellido
            : nombre_cliente = personaJuridica.nombreComercial;

        setNombreCliente(nombre_cliente);
    }, [personaNatural || personaJuridica]);



    const suma = (arr) => {
        let total = 0;
        arr.map(saldo => {
            total += saldo.saldo;
        })
        return total;
    }

    saldos.length > 0 ? saldo_total.current = suma(saldos) : saldo_total.current = 0;



    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        setScreenWidth(window.innerWidth);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if (loading) {
        return (
            <TransacLayout>
                <View>
                    <Col xs={20} sm={16} lg={16} className="m-auto transacciones_body">
                        <TransacSkeleton />
                    </Col>
                </View>
            </TransacLayout>
        )
    }

    return (
        <TransacLayout>
            <View>
                <div
                    style={{
                        maxWidth: "120rem",
                        margin: "0 auto",
                    }}
                >
                    <Col xs={24} className="bx_content_name" >
                        {screenWidth <= MEDIA_SCREENS.SM &&
                            <p className="uppa strong600"> ¡Hola, {nombreCliente}! </p>
                        }
                    </Col>
                    <Col xs={20} sm={20} lg={16} className="m-auto card-container transacciones_body">
                        <p className="main_sub_title">Mis Inversiones</p>
                        <Row gutter={[30, 20]}>
                            {saldos && saldos?.length > 0
                                ? saldos?.map((fondo) => (
                                    <Col xs={24} sm={24} md={12} lg={12} key={fondo?.codigoFondo}>
                                        <CardSlider key={fondo?.codigoFondo} codigoFondo={fondo?.codigoFondo} data={fondo} />
                                    </Col>

                                ))
                                : <p className="text-center">No existe saldos en la cuenta</p>
                            }
                        </Row>
                        <div className="flex-end div__resumen">
                            <p className="md bold uppa">Saldo Total: <span>${formatMoney(saldo_total.current)}</span></p>
                        </div>
                    </Col>
                </div>
            </View>
        </TransacLayout>
    )


};



const TransacSkeleton = () => {
    return (
        <BoxUi>
            <Row gutter={[16, 16]}>
                <Col xs={24} align="middle">
                    <Skeleton active paragraph={false} />
                </Col>
                <Col />
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Skeleton active title={false} />
                </Col>
                <Col xs={12}>
                    <Skeleton active avatar />
                </Col>
                <Col xs={12}>
                    <Skeleton active avatar />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col />
                <Col xs={24} align="middle">
                    <Skeleton active paragraph={false} />
                </Col>
            </Row>

        </BoxUi>

    )
}


const View = styled.div`
    .card-container{
        display: flex;
        justify-content: center;
    }

    .transacciones_body{
        display:flex;
        flex-direction:column;
        gap:1.8em;
     }

    min-height: inherit;
    p {
        margin: 0;
    }

    .main_sub_title {
        color: #373535;
    font-style: italic;
    font-size: 20px;
    font-family: 'Noto Sans', sans-serif;
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px){
        font-size: 16px;
    }

    }

    .bx_content_name {

        display: flex;
        align-items: center;
        justify-content: center;

        padding:2em;

        @media screen and (max-width: ${MEDIA_SCREENS.XS}px) {
            padding:1em;
        }
    }



    .info__date {
        color: gray;

        @media screen and (max-width: ${MEDIA_SCREENS.XS}px) {
            justify-content: center
        }

    }

    .bx-spin {
        min-height: inherit;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }


    .bxDate {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        @media screen and (max-width: ${MEDIA_SCREENS.XS}px) {
            justify-content: center
        }
    }

    nav.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    }


   
    .div__resumen {
        background: #f9f9f9;
        border-bottom:3pt solid ${THEME_COLORS.light.secondary.main};
        height: 100%;
        height:4.5em;
        padding:1em;
        @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
            padding: 5% 3% !important;
        }
        border-radius:${THEME_VARS.RADIUS}pt;
    }

`;

export default TransaccionesPage;
