import { useRouter } from "next/navigation";
import styled from "styled-components";
import { MEDIA_SCREENS, THEME_COLORS } from "theme/constants";
import { formatMoney } from "utils/moneyUtils";
import { MdFamilyRestroom } from 'react-icons/md';
import { useSelector } from "react-redux";


const CardSlider = ({ data, codigoFondo }) => {
  const router = useRouter();

  let names = data.descripcionFondo.split(' ');

  return (
    <CardView onClick={() => router.push(`/transacciones/${codigoFondo}`)}>
      <div
        className="cardProducto"
        style={{ minHeight: "100px" }}
      >
        <MdFamilyRestroom className="icon_fondo" />
        <div className="bx_fondo_descripcion" style={{
          lineHeight: "1.25rem",
        }}>
          <span
            style={{ fontSize: ".85rem" }}
          >{names[0]}</span>
          <br />
          <span
            style={{ fontSize: ".85rem" }}
          >{names[1] === 'ESTRATEGICO' ? 'ESTRATÉGICO' : names[1]}</span>
          <p></p>
        </div>

        <div className="bx_fondo_detalle">
          <div
            style={{
              display: "flex",
              gap: "2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <p className="sm bold">Saldo acumulado</p>
              <p className="">${formatMoney(data?.saldo)}</p>
            </div>
            <div className="txt-right">
              <p className="sm bold">{codigoFondo === '000001' ? 'Último aporte' : 'Última inversión'}</p>
              <p className="">
                $ {formatMoney(data.fechasDeposito?.montoUltimoPago)}
              </p>
            </div>
          </div>
          {/* <div className="flex-between">
            <div className="p-r-10">
              <p className="sm bold">Permanencia</p>
              {data.tipoFondo == "FLP" ? (
                <p className="sm">
                  {data.depositosFLP?.numeroDepositos} /{" "}
                  {data.depositosFLP?.numeroDepositosEsperados} Aportes
                </p>
              ) : (
                <p className="sm">{data.fechasDeposito?.tiempoPermanencia}</p>
              )}
            </div>
          </div> */}
        </div>

      </div>
    </CardView>
  );
};


const CardFondoDetail = ({ user, detailsFondo }) => {
  const { fechaPrimerAporte, fechaInicioCiclo, fechaFinCiclo, rendimiento } = useSelector((state) => state.resumenPersona);
  let names = [];

  if (detailsFondo?.descripcionFondo) {
    names = detailsFondo?.descripcionFondo?.split(' ');
  }

  // console.log({ fechaPrimerAporte, fechaInicioCiclo, fechaFinCiclo });

  return (
    <Card>
      <div className="header__mov__content">
        <div className="cardProducto">
          <div className="text-center mb-10">
            <MdFamilyRestroom className="icon_fondo" />
            <br />
            <span>{names[0]}</span>
            <span>{names[1]}</span>
            {/* <p className="sub_title xs">Acumulado</p>
            <p className="md bold">$ {formatMoney(detailsFondo?.saldo)} </p> */}
          </div>
          <div className="flex-evenly card__details__prod">
            <div>
              <p className="value">${formatMoney(detailsFondo?.saldo)}</p>
              <p className="description">Saldo Acumulado</p>
            </div>
            <div>
              {
                detailsFondo?.codigoFondo === '000001' ? (
                  <>
                    <p className="value">{fechaPrimerAporte?.split(' ')[0]}</p>
                    <p className="description">Fecha 1er Aporte</p>
                  </>
                ) : (
                  <>
                    <p className="value">$ {formatMoney(rendimiento)}</p>
                    <p className="description">Rendimiento</p>
                  </>
                )
              }
            </div>
            {
              detailsFondo?.codigoFondo === '000001' && (
                <>
                  <div>
                    <>
                      <p className="value">{fechaInicioCiclo?.split(' ')[0]}</p>
                      <p className="description">Fecha inicio ciclo vigente</p>
                    </>
                  </div>
                  <div>
                    <>
                      <p className="value">{fechaFinCiclo?.split(' ')[0]}</p>
                      <p className="description">Fecha fin ciclo vigente</p>
                    </>
                  </div>
                </>
              )
            }
            {/* <div>
              {detailsFondo.tipoFondo == "FLP" ? (
                <p className="xs">
                  {detailsFondo.depositosFLP?.numeroDepositos} /{" "}
                  {detailsFondo.depositosFLP?.numeroDepositosEsperados} Aportes
                </p>
              ) 
              : 
              (
                <p className="xs bold">5 días</p>
              )}
              <p className="sm">Permanencia</p>
            </div> */}
          </div>
        </div>
      </div>
    </Card>
  );
};

const CardView = styled.div`
  &:hover {
    cursor: pointer;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    background: #f6f6f6;
    transform: scale(1.02);
    transition: .2s;
  }


  // css para que el efecto sea con una bonita transicion
  


  transition: .2s;
  transform: scale(1);
  /* max-width: 500px; */
  margin: 0 auto;
  
  background:${THEME_COLORS.light.bg.main};
  border: 2pt solid ${THEME_COLORS.light.secondary.main};

  border-radius: 25px;
  .icon_fondo {
    position: absolute;
    top: -10px;
    left: -10px;
    font-size: 45px;
    border: 2px solid #a4ce4e;
    border-radius: 50px;
    padding: 3px;
    color: #45a2d9;
    background: white;
  }

  .bx_fondo_descripcion {
    border-bottom: 15px solid ${THEME_COLORS.light.primary.main};
    border-radius: 20px;
    
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
      width: 100%;
      border-bottom: 10px solid ${THEME_COLORS.light.primary.main};
      border-radius: 13px;
    }

  }

  .bx_fondo_descripcion p{
    background: ${THEME_COLORS.light.secondary.main};
    height: 5px;
    border-radius: 1px 1px 5px 5px
  }

  .bx_fondo_descripcion span{
    color: ${THEME_COLORS.light.primary.main};
    padding: 5px;
    font-size: 15px;

    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
      // padding-right: 8px;
    }
  }
  .bx_fondo_descripcion span:nth-child(3){
    font-weight: bold;
  }

  .bx_fondo_detalle {
    padding: 10px;
    padding-top: 0px;
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
      padding-top: 10px;
    }
  }


  @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
    width: 100%;

    .bx_fondo_detalle div div:nth-child(1){
      text-align: left;
    }
  }

  .go-corner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    overflow: hidden;
    top: 0;
    right: 0;
    background-color: ${THEME_COLORS.light.secondary.main};
    border-radius: 0 4px 0 32px;
  }

  .go-arrow {
    margin-top: -4px;
    margin-right: -4px;
    color: white;
    font-family: "Noto Sans", sans-serif;
  }

  .cardProducto {
    text-align: left;
    display: grid;
    grid-template-columns: 35% 1fr;
    top: 0px;
    border-radius: 4px;
    overflow: hidden;
    max-width: 500px;
    align-items: flex-end;

    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
      height: 100%;
      display: block;
      text-align: right;
      margin: auto;
      width: auto;
      padding: 5px 0;
    }
   

    /* &:hover:before {
      transform: scale(2.15);
    } */
  }
`;

const Card = styled.div`
  background: #f0f2f5;
  p {
    margin-bottom: 0;
  }
  
  .value {
    font-size: 15px !important;
    margin-bottom: 5px;
    font-weight: bold;
    color: #696767;
    
    @media (min-width: ${MEDIA_SCREENS.MD}px) {
      font-size: 1.2rem;
    }
  }

  .description {
    font-size: 12px !important;
    line-height: 1;
    color: #696969;
    @media (min-width: ${MEDIA_SCREENS.MD}px) {
      font-size: 1rem;
    }
  }

  .header__mov__content {
    padding: 25px 0;
  }

  .card__details__prod {
    width: 80%;
    margin: auto;
    padding: 10px;
    text-align: center;

    background: rgba(255, 255, 255, 0.31);
    border-radius: 20px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    border: 2px solid ${THEME_COLORS.light.secondary.main};

    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
      width: 95%;
      gap: 12px;
    }
  }

  .cardProducto {
    width: 60%;
    margin: auto;

    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
      width: 100%;
    }
  }

  .sub_title {
    color: #a9c615;
  }

  .icon_fondo {
    font-size: 45px;
    border: 2px solid #a4ce4e;
    border-radius: 50px;
    padding: 3px;
    color: #45a2d9;
    background: white;
  }

  .cardProducto span{
    color: ${THEME_COLORS.light.primary.main};
    padding: 5px;
    font-size: 18px;
  }
  .cardProducto span:nth-child(4){
    font-weight: bold;
  }


`;
export { CardSlider, CardFondoDetail };
