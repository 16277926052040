// export const fixMoney = (value) => {
//     if(!value) return 0;
//     return `$ ${value.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//   }

// export const fixMoney = (value) => {
//     if (!value) return '$ 0.00';

//     const num = Number(value).toFixed(2);
//     const parts = num.split('.');
//     const integerPart = parts[0];
//     const decimalPart = parts[1];

//     const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//     return `$ ${formattedInteger}.${decimalPart}`;
// };

export const fixMoney = (value) => {
    if (!value) return '$ 0.00';

    const num = Number(value).toFixed(2);

    // Usar toLocaleString para formatear la parte entera
    const formattedNum = Number(num).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return `$ ${formattedNum}`;
};




// function milesNumeros(numero) {
//     return numero.toString().replace(/(\.\d+)|\B(?=(\d{3})+(?!\d))/g, function (m, g1) {
//         return g1 || ","
//     });
// };

function milesNumeros(numero) {
    console.log({ numero });
    return Number(numero).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}


export const formatMoney = (value) => {

    let formateado = Number(value).toFixed(2)
    formateado = milesNumeros(formateado);
    console.log({ formateado });
    return formateado
}

